<template>
  <div class="top">
    <div class="container flex flex__space-between flex__align-center">
      <div class="flex flex__align-center">
        <img :src="getFileAccessHttpUrl(userData.avatar)" alt class="top__img" />
        <div>
          <div class="top__name">{{userData.realname}}</div>
          <div class="top__tip">
            这是你加入学习的第
            <span class="top__tip-day">{{day}}天</span>
          </div>
        </div>
      </div>
      <div class="flex pr80">
        <div class="top__credit">
          <div class="top__num">{{userData.score}}</div>
          <div class="top__text">获得学分</div>
        </div>
        <div class="top__integral">
          <div class="top__num">{{userData.point}}</div>
          <div class="top__text">获得红利分</div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
// import Vue from 'vue'
// import { USER_NAME, USER_AVATAR, POINT, SCORE, STUDY_TIME} from '@/store/mutation-types.js'
import { getFileAccessHttpUrl } from "@/utils/tools";
import { getUserData } from "@/api/user";
export default {
  data() {
    return {
      userData: {},
      day: 0
    };
  },
  computed: {
    getFileAccessHttpUrl() {
      return avatar => {
        return getFileAccessHttpUrl(avatar);
      };
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getUserData().then(res => {
        this.userData = res.result;
        this.setDate(this.userData.createTime)
        console.log(this.userData, '用户数据');
      });
    },
    // 处理时间
    setDate(date) {
      var dateArr = date.split(' ')[0]
      var time = new Date(dateArr).getTime() / 1000 // 创建时间的时间戳
      var today = new Date().getTime() / 1000 // 当前时间的时间戳
      var daySeconds = 3600 * 24 // 一天的秒数
      this.day = parseInt((today - time) / daySeconds)
      // console.log(this.day)
    }
  }
};
</script>
 
<style lang = "scss" scoped>
.top {
  height: 145px;
  background: url("~@/assets/images/user/user-bg.png") no-repeat;
  background-size: 100% 100%;
  &__img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  &__name {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  &__tip {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    &-day {
      color: #c4a468;
    }
  }
  &__credit {
    margin-right: 36px;
  }
  &__num {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  &__text {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
}
.pr80 {
  padding-right: 80px;
}
</style>