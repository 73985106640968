var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"side flex"},[_c('div',{staticClass:"side__left"},[_c('div',{staticClass:"side__ul no"},_vm._l((_vm.routes),function(item,index){return _c('div',{key:index,staticClass:"side__li",class:{
            side__li_active: _vm.count === item.name || _vm.parent === item.name,
          },on:{"click":function($event){return _vm.change(item.name)}}},[_c('div',{class:['li_item_ic', _vm.count === item.name || _vm.parent === item.name ? 'showNav' : '']},[_c('div',{staticClass:"li_item_ic_def"},[(
                  item.meta.icon == 'user' ||
                  item.meta.icon == 'book'
                )?_c('img',{staticClass:"qsy_img",attrs:{"src":item.meta.icon == 'user'
                    ? _vm.imgs.mime
                    : _vm.imgs.zhengshu,"alt":""}}):_vm._e()]),_c('div',{staticClass:"li_item_ic_act"},[(
                  item.meta.icon == 'user' ||
                  item.meta.icon == 'book'
                )?_c('img',{staticClass:"qsy_img",attrs:{"src":item.meta.icon == 'user'
                    ? _vm.imgs.mimeActive
                    : _vm.imgs.zhengshuActive,"alt":""}}):_vm._e()])]),_c('div',{staticClass:"li_item_name"},[_vm._v(_vm._s(item.meta.title))])])}),0)]),_c('div',{staticClass:"side__content"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }