<template>
  <div>
    <div class="side flex">
      <div class="side__left">
        <div class="side__ul no">
          <div
            class="side__li"
            :class="{
              side__li_active: count === item.name || parent === item.name,
            }"
            v-for="(item, index) in routes"
            :key="index"
            @click="change(item.name)"
          >
            <div :class="['li_item_ic', count === item.name || parent === item.name ? 'showNav' : '']">
              <div class="li_item_ic_def">
                <img
                  class="qsy_img"
                  v-if="
                    item.meta.icon == 'user' ||
                    item.meta.icon == 'book'
                  "
                  :src="
                    item.meta.icon == 'user'
                      ? imgs.mime
                      : imgs.zhengshu

                  "
                  alt=""
                />
              </div>
              <div class="li_item_ic_act">
                <img
                  class="qsy_img"
                  v-if="
                    item.meta.icon == 'user' ||
                    item.meta.icon == 'book'
                  "
                  :src="
                    item.meta.icon == 'user'
                      ? imgs.mimeActive
                      : imgs.zhengshuActive
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="li_item_name">{{ item.meta.title }}</div>
          </div>
        </div>
      </div>
      <div class="side__content">
        <slot />
      </div>
    </div>
  </div>
</template>
 
<script>
import Vue from "vue";
import { getMessageList } from "@/api/news.js";
import { NEWCOUNT } from "@/store/mutation-types.js";
export default {
  data() {
    return {
      routes: [],
      moduleText: "",
      count: "",
      parent: "",
      num: null,
      imgs: {
        mime: require("@/assets/images/homeImg/nav_mine.png"),
        mimeActive: require("@/assets/images/homeImg/nav_mine_active.png"),
        zhengshu: require("@/assets/images/homeImg/nav_zhengshu.png"),
        zhengshuActive: require("@/assets/images/homeImg/nav_zhengshu_active.png"),
      },
    };
  },
  created() {
    this.num = Vue.ls.get(NEWCOUNT);
    let arr = this.$router.options.routes;
    let res = [];
    this.count = this.$route.name;
    this.parent = this.$route.meta.parents;
    this.moduleText = this.$route.meta.moduleText;
    arr.forEach((item) => {
      if (item.meta.title === this.moduleText) {
        res = item.children;
        return;
      }
    });
    this.routes = res.filter((item) => {
      if (!item.meta.hideSide) {
        return item;
      }
    });
    this.routes = this.routes.filter(function(item) {
          return item.name !== "creditDetail"
    });
  },
  methods: {
    change(name) {
      if (name === this.count) return;
      this.$router.push({ name: name });
    },
    getMessage() {
      getMessageList().then((res) => {
        this.num = res.result;
      });
    },
  },
  mounted() {
    this.num = Vue.ls.get(NEWCOUNT);
    console.log(this.num, "111111111111");
    // console.log(this.count === 'newsDetails', '生命周期111')
    // if (this.count === 'news' || this.count === 'report' || this.count === 'train' || this.count === 'newsDetails') {
    //   this.getMessage();
    // }
  },
};
</script>
 
<style lang = "scss" scoped>
.side {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 24px 24px 200px;
  background: #ffffff;

  &__left {
    width: 242px;
    padding-bottom: 30px;
  }

  &__li {
    align-items: center;
    display: flex;
    /* text-align: center; */
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #989898;
    cursor: pointer;
    transition: all 0.6s;
    width: 242px;
    height: 62px;
    justify-content: center;

    &_active {
      background: #5db4ff;
      color: #ffffff;
    }

    &:hover {
      background: #5db4ff;
      color: #ffffff;
    }
    .li_item_ic{
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }
    .li_item_ic_act{
      display: none;
    }
    .li_item_ic_def{
      display: block;
    }
    .showNav .li_item_ic_act{
      display: block;
    }
    .showNav .li_item_ic_def{
      display: none;
    }
    &:hover .li_item_ic_def{
      display: none;
    }
    &:hover .li_item_ic_act{
      display: block;
    }
  }

  &__content {
    width: 890px;
    background: #fff;
    box-sizing: border-box;
    padding: 0 0 0 24px;
    display: flex;
  }
}

.qsy_img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>